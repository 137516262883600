.spiking-learn-container {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  @apply md:px-12 sm:px-8 px-6;
}

.event-calendar .fc-event {
  cursor: pointer;
}

.event-calendar .fc-event-time {
  flex-shrink: 0;
}

.event-calendar .fc-daygrid-day-top {
  padding-top: 4px;
  height: 30px;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}

.event-calendar .fc-event-main {
  height: 20px;
}

.event-calendar .fc-day-today {
  background-color: transparent !important;
}

.event-calendar .fc-popover {
  background: var(--fc-page-bg-color) !important;
}

.event-calendar .fc-event-title {
  font-size: 12px;
  font-weight: 500 !important;
}

.event-calendar .fc-day-today .fc-daygrid-day-top .fc-daygrid-day-number {
  justify-content: center;
  font-size: 12px;
  width: 24px;
  height: 24px;
  background-color: #2065f6;
  border-radius: 9999px;
  color: #fff;
  text-align: center;
}

.event-calendar .fc-col-header-cell .fc-scrollgrid-sync-inner {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 18px;
}

.event-calendar .fc-col-header-cell .fc-scrollgrid-sync-inner {
  align-items: center;
}

.event-calendar .fc-col-header-cell .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  /* position: absolute; */
  padding: 0px;
  bottom: 0px;
  height: 15px;
  color: #70757a;
}

/* .event-calendar .fc-col-header-cell {
  background-color: lightgray;
} */

.event-calendar .fc-h-event .fc-event-title {
  font-size: 12px;
}


.event-calendar .fc-event-time {
  font-size: 12px;
  font-weight: 400 !important;
}

.event-calendar .fc-theme-standard th {
  border-bottom: none;
}

.event-calendar .fc-col-header-cell-cushion {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
}

.calendar-mobile .fc-daygrid-more-link {
  @apply bg-branding-500 rounded-full h-1 w-1 touch-none pointer-events-none;
}

.calendar-mobile .fc-daygrid-day-events {
  left: auto !important;
  right: auto !important;
  top: 28px;
}

.calendar-mobile .fc-daygrid-day-number {
  @apply text-center text-sm -tracking-tight;
}

.calendar-mobile .fc-daygrid-day-frame {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-mobile .fc-theme-standard td,
.calendar-mobile .fc-theme-standard th {
  border: 0px !important;
}

.calendar-mobile .fc .fc-daygrid-day.fc-day-today {
  background-color: inherit !important;

}

.calendar-mobile .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-top {
  @apply w-7 h-7 rounded-full bg-branding-500 text-white flex items-center justify-center;
}

.calendar-mobile .fc-scrollgrid-liquid {
  border: 0px !important
}

.calendar-mobile .fc-scroller-liquid-absolute {
  overflow: unset !important;
}