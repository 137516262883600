@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../../fonts/OpenSans-Regular.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../../fonts/OpenSans-Medium.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../../fonts/OpenSans-SemiBold.ttf);
  font-style: bold;
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"), url(../../fonts/OpenSans-Bold.ttf);
  font-style: bold;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url(../../fonts/RobotoMono-Bold.ttf);
  font-style: bold;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url(../../fonts/RobotoMono-Regular.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url(../../fonts/RobotoMono-Medium.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url(../../fonts/RobotoMono-SemiBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: "Roboto Mono";
  src: local("Roboto Mono"), url(../../fonts/RobotoMono-Light.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

body {
  margin: 0;
  padding: 0;
  min-height: 0 !important;
  height: auto !important;
  position: inherit !important;
  top: inherit !important;
  /* padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top); */
  /* box-sizing: border-box; */
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.goog-te-banner-frame,
.goog-te-gadget {
  display: none !important;
}

.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  /* visibility: hidden; */
}

.side-nav-svgmain {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

::placeholder {
  text-overflow: ellipsis;
}

.reactPlayerVideo {
  position: absolute;
  top: 0;
  left: 0;
}

.video-js {
  width: 100%;
  height: 100%;
}

/* .jss12 {
    display: none !important;
}

@media only screen and (max-width: 959.95px) {
    .jss12 {
        display: block !important;
    }
} */

/* .hide_flag {
    display: none;
} */

/* width */
/* ::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  } */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #888;
  } */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */

.spiking-container {
  @apply w-full px-4 md:px-10 lg:px-4 lg:max-w-[1036px] lg:mx-auto xl:px-0 duration-200;
}

.apexcharts-tooltip {
  background: transparent !important;
  box-shadow: none !important;
  padding: 20px;
}

.btn-primary {
  @apply bg-branding-500 border border-branding-500 hover:border-primary active:border-branding-600 hover:bg-primary active:bg-branding-600 disabled:bg-neutral-400 dark:disabled:bg-dark-neutral-400 text-white duration-150;
}

.btn-secondary {
  @apply bg-branding-50 dark:bg-dark-link-50 border border-branding-50 dark:border-dark-link-50 hover:border-branding-50 dark:hover:border-dark-link-50 active:border-primary text-branding-500 hover:bg-branding-50 hover:text-primary active:bg-primary active:text-white dark:active:text-dark-neutral-800 disabled:bg-neutral-400 dark:disabled:bg-dark-neutral-400 disabled:text-neutral-500 dark:disabled:text-dark-neutral-500 duration-150;
}

.btn-outlined {
  @apply border border-neutral-300 dark:border-dark-neutral-300 text-branding-500 bg-white dark:bg-dark-neutral-50 hover:border-branding-200 dark:hover:border-branding-200 hover:shadow-down-m dark:active:border-branding-500 active:border-branding-500 active:bg-branding-50 disabled:bg-neutral-400 dark:disabled:bg-dark-neutral-400 disabled:text-neutral-500 dark:disabled:text-dark-neutral-500 duration-150;
}

.btn-outlined-red {
  @apply border border-neutral-300 dark:border-dark-neutral-300 text-error-500 bg-white dark:bg-dark-neutral-50 hover:border-error-200 hover:shadow-down-m active:border-error-500 dark:active:border-dark-error-500 active:bg-error-50 dark:active:bg-dark-error-50 disabled:bg-neutral-400 dark:disabled:bg-dark-neutral-400 disabled:text-neutral-500 dark:disabled:text-dark-neutral-500 duration-150;
}

.input-primary {
  @apply rounded-lg border border-neutral-300 dark:border-dark-neutral-300 text-base bg-neutral-50 dark:bg-dark-neutral-25 focus:bg-white dark:focus:bg-dark-neutral-50 px-3 py-2.5 duration-150;
}

.btn-delete {
  @apply bg-error-50 dark:bg-dark-error-50 hover:bg-error-100 dark:hover:bg-dark-error-100 active:bg-error-200 dark:active:bg-dark-error-200 disabled:bg-neutral-400 dark:disabled:bg-dark-neutral-400 text-error-500 dark:text-dark-error-500 disabled:text-neutral-500 dark:disabled:text-dark-neutral-500 duration-150;
}

.trick-mobile-browser {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.legal-privacy {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 11px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
}

.legal-privacy a {
  text-decoration: none;
}

.intro-tooltip-container {
  padding: 0;
  width: 399px;
  background: #ffffff;
  box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05);
  border-radius: 20px !important;
}

.introjs-tooltip-header {
  display: none;
}

.introjs-tooltipbuttons {
  border-top: 0px solid #e0e0e0 !important;
  padding: 0px 20px 20px 20px !important;
  margin-top: -10px;
}

.highlight-tooltip-class {
  border: #ffffff 2px solid;
}

.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: 0px !important;
  border: 0px solid #bdbdbd !important;
  text-decoration: none;
  text-shadow: none !important;
  font-size: 13px !important;
  color: #fff !important;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  background-color: #2065f6 !important;
  border-radius: 40px !important;
  zoom: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 28px;
  outline: none !important;
}

.introjs-showElement {
  background-color: inherit;
}
.swiper-discover-moving-ticker > .swiper-wrapper {
  transition-timing-function: linear;
}

/* ************** */

/* Container box to set the sides relative to */
/* .cube {
	width: 100px;
	text-align: center;
	margin: 0 auto;
  
 -webkit-transition: -webkit-transform .3s;
	transition: transform .3s; 

	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d; 
  height: 100px;
} */

/* .flip,.flop {
	border: 2px solid black;
  background: #3d405b;
  height: 100%;
   
} */

/* 
.flip {
	-webkit-transform: translateZ(50px);
	transform: translateZ(50px);
  
  
}

.flop {
	-webkit-transform: rotateX(-90deg) translateZ(-50px);
	transform: rotateX(-90deg) translateZ(-50px);
} */

/* rotation */
/* .cube:hover {
	-webkit-transform: rotateX(360deg);
	transform: rotateX(360deg);
} */

.cube {
  animation: rotate 3s infinite;
  -webkit-animation: rotate 3s infinite;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  15% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  15% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.swiper-discover-moving-ticker > .swiper-wrapper {
  transition-timing-function: linear;
}

/* ************** */

/* Container box to set the sides relative to */
/* .cube {
	width: 100px;
	text-align: center;
	margin: 0 auto;
  
 -webkit-transition: -webkit-transform .3s;
	transition: transform .3s; 

	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d; 
  height: 100px;
} */

/* .flip,.flop {
	border: 2px solid black;
  background: #3d405b;
  height: 100%;
   
} */

/* 
.flip {
	-webkit-transform: translateZ(50px);
	transform: translateZ(50px);
  
  
}

.flop {
	-webkit-transform: rotateX(-90deg) translateZ(-50px);
	transform: rotateX(-90deg) translateZ(-50px);
} */

/* rotation */
/* .cube:hover {
	-webkit-transform: rotateX(360deg);
	transform: rotateX(360deg);
} */

.cube {
  animation: rotate 3s infinite;
  -webkit-animation: rotate 3s infinite;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  15% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotateX(0deg);
  }
  15% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.tradegpt-transcript > message-content > div > p {
  margin: revert;
}

.tradegpt-transcript > message-content > div > ul {
  margin: revert;
  list-style: initial;
  padding: revert;
  padding-left: 30px;
}

.tradegpt-transcript > message-content > div > ul > li > ul {
  margin: revert;
  list-style: initial;
  padding: revert;
  padding-left: 30px;
}

.centurionBorder {
  text-align: center;
  position: relative;
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: lightgrey;
  border-radius: 80px;
  color: transparent;
  background-clip: border-box, text;
  -moz-background-clip: border-box, text;
  -webkit-background-clip: border-box, text;
  background-image: radial-gradient(
      circle,
      transparent,
      transparent 57%,
      yellow 58%,
      red 100%
    ),
    repeating-linear-gradient(
      -40deg,
      yellow,
      yellow 10%,
      orange 21%,
      orange 30%,
      yellow 41%
    );
  line-height: 100px;
}

.centurionImage {
  position: absolute !important;
  width: 40px !important;
  height: 40px !important;
  top:5px ;
  right: 5px;
}
/* 
.tradegpt-transcript > div {
  font-size: 13px;
} */

.background-gradient-running-text {
  background-image: linear-gradient(
    91.58deg,
    #f51a1a 15.86%,
    rgba(133, 31, 250, 0.972) 31.62%,
    #9403b8 45.81%,
    #ea69ff 61.03%,
    #f50699 74.27%,
    #3aa0f3 88.56%
  );
  background-size: 500% 500%;
  -webkit-animation: rainbow 10s ease infinite;
  -z-animation: rainbow 10s ease infinite;
  -o-animation: rainbow 10s ease infinite;
  animation:
    rainbow 10s ease infinite,
    horizontal-shaking 0.5s ease infinite;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}


.advertise-banner-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.advertise-banner-visible-active {
  opacity: 1;
  visibility: visible;
}
